<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
        Permisos
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <PermissionsTable :headers="headers" :boards="boards">

       </PermissionsTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import PermissionsTable from '@/components/PermissionsTable.vue' 
export default {
  name: 'Permissions',
  components: {
    PermissionsTable
  },
  data() {
    return {
      headers: {
        permissions: [
          {
            text: 'Completo',
            align: 'end',
            value: 'complete'
          },
          {
            text: 'Listar',
            align: 'end',
            value: 'list'
          },
          {
            text: 'Crear',
            align: 'end',
            value: 'create'
          },
          {
            text: 'Editar',
            align: 'end',
            value: 'update'
          },
          {
            text: "Eliminar",
            align: 'end',
            value: 'delete'
          }
        ],
      },
      boards: {
        role: 'role',
        permissions: 'permissions'
      }
    }
  },
}
</script>

<style>

</style>