<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="12">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col cols="12" sm="12" md="3" >
                <h5>Permisos</h5>
              </v-col>
              <v-col cols="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="modelSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
            </v-row>

            <v-row align="center" justify="space-around">
              <v-col cols="12" md="6">
                <v-autocomplete
                  class="text-caption"
                  v-model="role"
                  item-value="id"
                  dense
                  :items="role_records"
                  item-text="name"
                  label="Rol"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.role)"
                >
                  Crear Rol
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-row>
              <v-col ms="12" md="3">
                <v-checkbox dense class="ml-4" label="Todos los permisos" @change="observablePermissions('all')"></v-checkbox>
              </v-col>
              <v-col ms="12" md="2">
                <v-checkbox dense class="ml-4" label="Listar" @change="observablePermissions('list')"></v-checkbox>
              </v-col>
              <v-col ms="12" md="2">
                <v-checkbox dense class="ml-4" label="Crear" @change="observablePermissions('add')"></v-checkbox>
              </v-col>
              <v-col ms="12" md="2">
                <v-checkbox dense class="ml-4" label="Editar" @change="observablePermissions('update')"></v-checkbox>
              </v-col>
              <v-col ms="12" md="2">
                <v-checkbox dense class="ml-4" label="Eliminar" @change="observablePermissions('delete')"></v-checkbox>
              </v-col>
            </v-row>
            
            <v-data-table
              dense
              :headers="headers.permission"
              :items="model_records"
              class=" rounded-xl"
              :search="modelSearch"
              :loading="loadingPermissionsTable"
            >
              <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{item.model.replaceAll('_', ' ')}}
                    </td>
                    <td>
                      <v-checkbox dense v-model="modelPermissions[item.model].complete" @change="observablePermissions(`/${item.model}`)"></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox dense v-model="modelPermissions[item.model].list" @change="observablePermissions(`/${item.model}/list`)"></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox dense v-model="modelPermissions[item.model].add" @change="observablePermissions(`/${item.model}/add`)"></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox dense v-model="modelPermissions[item.model].update" @change="observablePermissions(`/${item.model}/update`)"></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox dense v-model="modelPermissions[item.model].delete" @change="observablePermissions(`/${item.model}/delete`)"></v-checkbox>
                    </td>
                  </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Role Form -->
    <v-row justify="center" >
      <v-form ref="createRoleForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="roleDialog" persistent max-width="600px">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">Crear rol</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createRoleForm.name"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.role)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.role)"
              >
                Guardar y cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>
    
  </div>
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //loadings
      isLoading: false,
      loadingPermissionsTable: false,
      //search
      modelSearch: '',
      //records
      model_records: [
        { model: 'segment', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'market', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'customer', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'document_id_type', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'vip_status', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'repeat_guess', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'email', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'nationality', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'gender', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'business_type', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'supplier', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'family', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'subfamily', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'product', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'agents_commission', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'rate_date', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'product_price', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'currency', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'exchange_rate', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'email_operation', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'reservation', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'reservation_status', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'tax', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'payment_method', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'user', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'role', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'permissions', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'calendar_operation', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'invoice', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'invoice_detail', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'discount', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'folio', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'receipt', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'assignment', complete: false, list: false, add: false, update: false, delete: false },
        { model: 'vehicle', complete: false, list: false, add: false, update: false, delete: false }
      ],
      role_records: [],
      //form
      valid: false,
      roleDialog: false,
      createRoleForm: {},
      role: null,
      //form rules
      createFormRules: {
        required: value => !!value || 'El campo es requerido.',
        counter: value => value ? value.length <= 25 || 'Max 25 Caracteres': '',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      selectedRecord: null,
      modelPermissions: {
        segment: { complete: false, list: false, add: false, update: false, delete: false },
        market: { complete: false, list: false, add: false, update: false, delete: false },
        customer: { complete: false, list: false, add: false, update: false, delete: false },
        document_id_type: { complete: false, list: false, add: false, update: false, delete: false },
        vip_status: { complete: false, list: false, add: false, update: false, delete: false },
        repeat_guess: { complete: false, list: false, add: false, update: false, delete: false },
        email: { complete: false, list: false, add: false, update: false, delete: false },
        nationality: { complete: false, list: false, add: false, update: false, delete: false },
        gender: { complete: false, list: false, add: false, update: false, delete: false },
        business_type: { complete: false, list: false, add: false, update: false, delete: false },
        supplier: { complete: false, list: false, add: false, update: false, delete: false },
        family: { complete: false, list: false, add: false, update: false, delete: false },
        subfamily: { complete: false, list: false, add: false, update: false, delete: false },
        product: { complete: false, list: false, add: false, update: false, delete: false },
        agents_commission: { complete: false, list: false, add: false, update: false, delete: false },
        rate_date: { complete: false, list: false, add: false, update: false, delete: false },
        product_price: { complete: false, list: false, add: false, update: false, delete: false },
        currency: { complete: false, list: false, add: false, update: false, delete: false },
        exchange_rate: { complete: false, list: false, add: false, update: false, delete: false },
        email_operation: { complete: false, list: false, add: false, update: false, delete: false },
        reservation: { complete: false, list: false, add: false, update: false, delete: false },
        reservation_status: { complete: false, list: false, add: false, update: false, delete: false },
        tax: { complete: false, list: false, add: false, update: false, delete: false },
        payment_method: { complete: false, list: false, add: false, update: false, delete: false },
        user: { complete: false, list: false, add: false, update: false, delete: false },
        role: { complete: false, list: false, add: false, update: false, delete: false },
        permissions: { complete: false, list: false, add: false, update: false, delete: false },
        calendar_operation: { complete: false, list: false, add: false, update: false, delete: false },
        invoice: { complete: false, list: false, add: false, update: false, delete: false },
        invoice_detail: { complete: false, list: false, add: false, update: false, delete: false },
        discount: { complete: false, list: false, add: false, update: false, delete: false },
        folio: { complete: false, list: false, add: false, update: false, delete: false },
        receipt: { complete: false, list: false, add: false, update: false, delete: false },
        assignment: { complete: false, list: false, add: false, update: false, delete: false },
        vehicle: { complete: false, list: false, add: false, update: false, delete: false }
      },
      permissions: {
        removed: [],
        added: [],
        currents: []
      }
    }
  },
  computed: {
  },
  methods: {
    async summitForm(method, board){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.role) {
        if (!this.$refs.createRoleForm.validate()) {
          error = true
        } else {
          form = this.createRoleForm
        }
      }

      if (!error) {
        await this.$store.dispatch('verifyToken')

        axiosN506(`${board}/add`, {
          method: "POST",
          data: form,
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        })
        .then(() => {
          this.isLoading = false
          this.getData(board)
          this.closeForm(board)
        }).catch((err) => {
          alert(`error: ${err.response ? err.response : err}`)
          this.isLoading = false          
        })
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    openCreateForm(board) {
      if (board == this.boards.role) this.roleDialog = true
    },
    closeForm(board) {
      if (board == this.boards.role) {
        this.roleDialog = false
        this.$refs.createRoleForm.resetValidation()
        this.createRoleForm = {}
      }
      this.isLoading = false
      this.selectedRecord = null
    },
    getData(board){
      this.loadingTable = true
      this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.role) {
          this.role_records = result.data.data
          this.loadingPermissionsTable = false
        }

        if (board == this.boards.permissions) {
          // for (let item of result.data.data) {
          //   this.permissions.currents.push(item.path)
          // }
        }
      }).catch((err) => {
        console.error(err)
      });
    },
    observablePermissions(item) {
      console.log(item)
      if (item === 'all') {
        for (let model of this.model_records) this.modelPermissions[model] = { complete: true, list: true, add: true, update: true, delete: true }
      } else {
        for (let model of this.model_records) this.modelPermissions[model] = { complete: false, list: false, add: false, update: false, delete: false }
        // if (item && this.permissions.currents.indexOf(item) == -1) this.permissions.added.push(item)
      }
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  created() {
    this.getData(this.boards.role)
    this.getData(this.boards.permissions)
  }
}
</script>